<template>
  <Breadcrumb
    :urls="urls"
    :translationloaded="translationloaded"
    :translate="translate"
  ></Breadcrumb>
  <WhatsAppButton></WhatsAppButton>
  <div class="checkOutMainAllDiv" v-if="translationloaded == true">
    <div class="container">
      <div
        class="subCheckOutWrapperMain"
        v-if="OrderMode != 'Pickup From Store'"
      >
        <CustomerAddressList
          :addressList="fetchCustomerAddressList"
          @deleteCustomerAddress="deleteAddress"
          @editAddress="editCustomerAddress"
          @selectedCustId="selectedCustAddressId"
          @cartUpdated="cartDataUpdated"
          :translationloaded="translationloaded"
          :translate="translate"
        ></CustomerAddressList>

        <div class="subCheckOutWrapperDiv">
          <button @click="addressForm">{{ translate("addNewAddress") }}</button>
        </div>
      </div>

      <div class="cart_payment">
        <div class="adreessPageCartSecWreapper">
          <div class="check_out_cart">
            <SideCart
              @updateCartQantity="updateCart"
              @deleteCartItem="rmvCartItem"
              :cartItems="getcartList"
              :base_url="web_admin_url"
              :currency="currency"
              :qtyReset="qtyReset"
              @cartQtyCntReset="qtyCntReset"
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideCart>
          </div>
          <div>
            <h4>{{ translate("OrderNote") }}</h4>
            <textarea class="orderNote" v-model="orderNote"></textarea>
          </div>
        </div>

        <div class="adreessPageCartSecDiv">
          <!-- <PaymentDetails
            :currency="currency"
            :paymentInfo="getCartSummaryList"
            :cartItems="getcartList"
             :translationloaded="translationloaded"
            :translate="translate"
          >
          </PaymentDetails> -->
          <h4>{{ translate("OrderSummary") }}</h4>
          <OrderSummary
            :cart_summary="getCartSummaryList"
            :cartData="getcartList"
            :getItemCnt="getItemCnt"
            :translationloaded="translationloaded"
            :translate="translate"
            :isCheckout="true"
          ></OrderSummary>

          <!-- <h3>{{translate('applyPromoCode')}}</h3>
            <ApplyPromoCode
              :cart_summary="getCartSummaryList"
              :selectedCoupon="selectedCoupon"
              :coupon_list="coupon_list"
              @applayCoupon="applyOffer"
              :translationloaded="translationloaded"
              :translate="translate"
            >
            </ApplyPromoCode> -->
        </div>
      </div>

      <!--use wallet check box start-->

      <div
        class="useWallet"
        v-if="
          walletTransaction.balance != 0 &&
            walletTransaction.balance != '' &&
            walletTransaction.balance != null
        "
      >
        <div class="d-flex" style="clear:both">
          <input type="checkbox" v-model="useWalletCheck" :value="true" />
          <label class="pl-2 pr-2"
            >{{ translate("useMy") }} {{ translate("walletAmount") }}:
            {{ currency }} {{ walletTransaction.balance }}
          </label>
        </div>
      </div>
      <!--use wallet check box end-->

      <div
        class="anotherPaymentMethodDiv"
        v-if="selected_cust_billing_address != ''"
      >
        <PaymentOption
          @selectedPaymentOption="paymentMethod"
          :translationloaded="translationloaded"
          :translate="translate"
        ></PaymentOption>
      </div>

      <template v-if="isProductAvailable == true">
        <div class="payment-btn" v-if="selected_cust_billing_address != ''">
          <button type="submit" @click="addressAvailabilityCheck">
            <template v-if="cart_ajax == 'closed'">
              {{ translate("completeOrder") }}
            </template>
            <template v-if="cart_ajax == 'open'">
              <ButtonLoader
                :loaderColor="loaderColor"
                :loaderStyle="loaderStyle"
                :cart_ajax="cart_ajax"
              >
              </ButtonLoader>
            </template>
          </button>
        </div>
      </template>

      <!-- <div v-if="getCartSummaryList.checkoutMsg!=''" class="alert alert-danger" role="alert">
       <template v-if="lang=='ar'">
          {{getCartSummaryList.checkoutMsgAr}}
       </template>
       <template v-else>
          {{getCartSummaryList.checkoutMsg}}
        </template> 
    
    </div> -->
      <div class="modal-vue" v-if="getCartSummaryList.checkoutMsg != ''">
        <div
          class="overlay"
          v-if="getCartSummaryList.checkoutMsg != ''"
          @click="getCartSummaryList.checkoutMsg = ''"
        ></div>
        <div class="alertModal">
          <button class="close" @click="getCartSummaryList.checkoutMsg = ''">
            x
          </button>
          <div v-if="lang == 'ar'">
            <p class="msgColor">
              {{ getCartSummaryList.checkoutMsgAr }}
              <span style="font-size: 20px; padding: 6px"
                ><i class="fa fa-exclamation-triangle" aria-hidden="true"></i
              ></span>
            </p>
          </div>
          <div v-else>
            <p class="msgColor">
              <span style="font-size: 20px; padding: 6px"
                ><i
                  class="fa fa-exclamation-triangle"
                  aria-hidden="true"
                ></i></span
              >{{ getCartSummaryList.checkoutMsg }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <AddressForm
    :openStatus="address_from_open_status"
    @hideAddressForm="hideForm"
    :updateAddress="edit_customer_address"
    :isAddAddresClick="add_address_btn_click"
    :translationloaded="translationloaded"
    :translate="translate"
  >
  </AddressForm>
  <OfferPopup
    :coupon_list="coupon_list"
    @coupon_code="getSelectedCoupon"
    :translationloaded="translationloaded"
    :translate="translate"
    :currency="currency"
  ></OfferPopup>
  <button @click="backtoTop" id="myBtn" title="Go to top">
    {{ translate("backToTop") }}
  </button>
</template>
<script>
import Config from "@/config";
import Breadcrumb from "@/components/Breadcrumb";
import SideCart from "@/components/checkout/SideCart";
// import PaymentDetails from "@/components/checkout/PaymentDetails";
import AddressForm from "@/components/checkout/AddressForm";
import CustomerAddressList from "@/components/checkout/CustomerAddressList";
import PaymentOption from "@/components/checkout/PaymentOption";
// import ApplyPromoCode from "@/components/cart/ApplyPromoCode";
import { mapGetters, mapActions } from "vuex";
import ButtonLoader from "@/components/ButtonLoader";
import WhatsAppButton from "@/components/WhatsAppButton";
import $ from "jquery";
import axios from "axios";
import moment from "moment";
// import DatepickerLite from 'vue3-datepicker-lite';
import OfferPopup from "@/components/cart/OfferPopup";
import OrderSummary from "@/components/cart/OrderSummary";

export default {
  name: "Checkout",
  props: ["locationupdated", "translationloaded", "translate"],
  watch: {
    locationupdated(value) {
      if (value == true) {
        this.fetchDeliverySlotList();
      }
      console.log(this.translationloaded);
    },
    useWalletCheck(value) {
      if (value == true) {
        this.applyWallet();
      } else if (value == false) {
        this.removeWallet();
      }
    },
    getCartSummaryList(cartSummary) {
      if (cartSummary != "") {
        if (cartSummary.WalletAmount > 0) {
          this.useWalletCheck = true;
        }

        if (cartSummary.WalletAmount > 0 && cartSummary.grandTotal == 0) {
          this.selected_payment_method = "wallet";
        } else {
          this.selected_payment_method = "COD";
        }
      }
    },
  },
  data() {
    return {
      lang: "",
      urls: [
        {
          path: "/",
          name: "Home",
        },
        {
          path: "cart",
          name: "Cart",
        },
        {
          path: "/checkout",
          name: "Payment",
        },
      ],
      useWalletCheck: false,
      walletTransaction: "",
      web_admin_url: "",
      currency: "",
      address_from_open_status: false,
      edit_customer_address: "",
      add_address_btn_click: "",
      selected_cust_shipping_address: "",
      selected_cust_billing_address: "",
      selected_payment_method: "COD",
      cart_ajax: "closed",
      loaderColor: "#fff",
      loaderStyle: {
        width: "35px",
      },
      choosed_dekivery_date: new Date(),
      datepickerSetting: {
        id: "delivery_slot",
        name: "delivery_slot",
        class: "myDateInput",
        value: "",
        placeholder: "Select date",
        disableInput: false,
        disabledDate: [],
      },
      selectedDeliveryDate: new Date(),
      deliverySlotes: [],
      availableDeliverySlotes: [],
      selectedDeliverySlot: "",
      delivery_type: "delivery",
      isCartProductAvailable: true,
      deliveryTypeData: [],
      qtyReset: 0,
      initialVendorURLKey: "",
      coupon_list: "",
      selectedCoupon: "",
      OrderMode: "delivery",
      vendorList: [],
      presentvendorUrl: "",
      weekDays: [],
      presentDay: "",
      nextDay: "",
      defaultActive: true,
      orderNote: "",
      showModal: false,
    };
  },
  components: {
    Breadcrumb,
    SideCart,
    // PaymentDetails,
    AddressForm,
    CustomerAddressList,
    PaymentOption,
    ButtonLoader,
    WhatsAppButton,
    // DatepickerLite,
    // ApplyPromoCode,
    OfferPopup,
    OrderSummary,
  },
  computed: {
    ...mapGetters([
      "getcartList",
      "getCartSummaryList",
      "fetchCustomerAddressList",
    ]),
    isProductAvailable() {
      let productAvailableStatus = true;

      let $this = this;
      $this.getcartList.forEach(function(product) {
        if (product.IsAvailPincode != true) {
          productAvailableStatus = false;
        }

        if (product.status != true || product.delDate != null) {
          productAvailableStatus = false;
        }

        if (product.qoh < product.qty && product.backOrders == false) {
          productAvailableStatus = false;
        }

        if (
          product.maxQtyInOrders < product.qty &&
          product.maxQtyInOrders != null &&
          product.maxQtyInOrders != 0
        ) {
          productAvailableStatus = false;
        }

        if (
          product.stockAvailability &&
          product.stockAvailability.toLowerCase() == "out of stock"
        ) {
          productAvailableStatus = false;
        }
      });

      return productAvailableStatus;
    },
    getItemCnt() {
      let itemCnt = 0;
      let itemCntHtml = "";
      if (this.getcartList != "") {
        itemCnt = this.getcartList.length;
      }
      if (itemCnt == 0) {
        itemCntHtml = `0 ${this.translate("item")}`;
      } else {
        itemCntHtml = `${itemCnt} ${this.translate("items")}`;
      }

      return itemCntHtml;
    },
    checkDeliveryTime() {
      let now = new Date(
        new Date().toLocaleString("en-US", { timeZone: "Asia/Muscat" })
      );
      let time = "05:00 PM";
      let dt =
        now.getMonth() +
        1 +
        "/" +
        now.getDate() +
        "/" +
        now.getFullYear() +
        " " +
        time;
      let userval = new Date(dt);
      if (now > userval) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.generateSevenSlotDays();
    this.lang = localStorage.getItem("lang");

    this.web_admin_url = Config.WEB_ADMIN_URL;
    this.currency = Config.CURRECNCY;
    this.presentvendorUrl = Config.VENDOR_URL_KEY;

    let $this = this;
    $this.getCustomerAddressList();
    $this.getCustomerCouponList();
    $this.fetchCustomerWalletAmount();

    let disabledDates = [];
    let currentDate = new Date();

    this.presentDay = $this.getWeekDay(currentDate);
    this.nextDay = $this.getNextDay();
    let presentDay = currentDate.getDate();
    let presentMonth = currentDate.getMonth() + 1;
    presentMonth = presentMonth.toString().padStart(2, "0");
    for (let i = presentDay - 1; i > 0; i--) {
      disabledDates.push(
        `${currentDate.getFullYear()}/${presentMonth}/${i
          .toString()
          .padStart(2, "0")}`
      );
    }

    $this.datepickerSetting.disabledDate = disabledDates;
    $this.datepickerSetting.value = `${currentDate.getFullYear()}/${presentMonth}/${presentDay
      .toString()
      .padStart(2, "0")}`;
    $this.getDeliverySlot().then(function(response) {
      $this.deliverySlotes = response.data.Data;

      $this.selectedDate($this.selectedDeliveryDate);
    });

    let guestId = null;
    let custId = null;
    custId = localStorage.getItem("custId");
    if (custId == null) {
      guestId = localStorage.getItem("guestId");
    }

    let payload = {
      cusId: custId,
      guestId: guestId,
      vendorUrlkey: Config.VENDOR_URL_KEY,
    };
    $this.getCart(payload);
    $this.getVendorList().then(function(response) {
      $this.vendorList = response.data.Data;
    });
  },
  methods: {
    ...mapActions([
      "getCustomerAddressList",
      "deleteCustomerAddress",
      "placeOrder",
      "getCart",
      "storeCartSummary",
      "getDeliverySlot",
      "switchDeliveryMod",
      "getVendorList",
      "getAreaByVendor",
    ]),

    SwitchDeliveryModel(delMod) {
      this.OrderMode = delMod;
      this.setDeliveryType(delMod);
      if (delMod == "Pickup From Store") {
        this.delivery_type = "Pickup From Store";
      }
    },
    getWeekDay(date) {
      let weekDayName = moment(date).format("dddd");
      return weekDayName;
    },
    getNextDay() {
      let today = "";
      let date = "";
      today = moment();
      date = moment(today).add(1, "days");

      let nextDay = moment(date).format("dddd");

      return nextDay;
    },
    getDateFormat(date) {
      let formatedDate = moment(date).format("D MMMM");
      return formatedDate;
    },
    generateSevenSlotDays() {
      var today = moment();
      let sevenDays = [];
      for (let i = 0; i < 7; i++) {
        let day = moment(today).add(i, "days");
        sevenDays.push(day);
      }

      this.weekDays = sevenDays;
    },
    selectDeliverySlot(slot) {
      this.selectedDeliverySlot = slot;
    },
    UpdateStore() {
      let headers = {
        headers: {
          vendorUrlKey: this.presentvendorUrl,
          token: "",
          lang: "",
        },
      };
      let $this = this;

      this.getAreaByVendor(headers).then(function(response) {
        if (response.data) {
          $this.$cookies.set("location_title", response.data.Data.area);
          $this.$cookies.set("location_info", response.data.Data);
          localStorage.setItem(
            "location_info",
            JSON.stringify(response.data.Data)
          );
          $this.$emit("location_updates");
        }
      });
    },

    cartDataUpdated(cartData) {
      this.$emit("cartUpdated", cartData);
    },
    fetchDeliverySlotList() {
      let $this = this;
      $this.getDeliverySlot().then(function(response) {
        $this.deliverySlotes = response.data.Data;

        $this.selectedDate($this.selectedDeliveryDate);
      });
    },
    setDeliveryType(type) {
      this.delivery_type = type;
      let $this = this;
      $this.switchDeliveryMod(type).then(function(response) {
        $this.deliveryTypeData = response.data.Data;
      });
    },
    selectedDate(value) {
      this.selectedDeliveryDate = value;
      if (this.getWeekDay(value) != this.presentDay) {
        this.defaultActive = false;
      }

      this.checkAlocatedSlotes();
    },
    checkAlocatedSlotes() {
      let $this = this;
      $this.availableDeliverySlotes = [];
      let selectedDate = new Date($this.selectedDeliveryDate);
      selectedDate =
        selectedDate.getDate() +
        "-" +
        selectedDate.getMonth() +
        "-" +
        selectedDate.getFullYear();
      if ($this.deliverySlotes != "") {
        $this.deliverySlotes.forEach(function(item) {
          let slotDate = new Date(item.slotDate);
          slotDate =
            slotDate.getDate() +
            "-" +
            slotDate.getMonth() +
            "-" +
            slotDate.getFullYear();
          if (selectedDate == slotDate) {
            $this.availableDeliverySlotes.push(item);
          }
        });

        if ($this.availableDeliverySlotes == "") {
          $this.selectedDeliverySlot = "";
        }
      }
    },

    backtoTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    addNewAddress() {
      this.address_from_open_status = true;
      this.add_address_btn_click = true;
    },
    hideForm() {
      this.address_from_open_status = false;
      $("#address_form_popup").removeClass("show");
      $("#address_form_popup").css({ display: "none" });
      $(".ps-site-overlay").css({ visibility: "hidden", opacity: " 0" });
    },
    deleteAddress(addressId) {
      let $this = this;
      $this
        .deleteCustomerAddress(addressId)
        .then(function(response) {
          $this.$toast.success(`${response.data.Message}`);
        })
        .catch(function(error) {
          $this.$toast.error(`${error.response.data.Message}`);
        });
    },
    editCustomerAddress(address) {
      this.edit_customer_address = address;
      this.address_from_open_status = true;
      this.add_address_btn_click = false;
      this.openAddressFormPopup();
    },
    selectedCustAddressId(addressId) {
      this.selected_cust_shipping_address = addressId;
      this.selected_cust_billing_address = addressId;
      this.$emit("location_updates", true);
    },
    addressAvailabilityCheck() {
      let $this = this;
      $this.cart_ajax = "open";
      if (this.selected_cust_shipping_address != "") {
        axios
          .get(
            `${Config.BASE_URL}Customer/CheckAddressDeliverable?custAdressId=${this.selected_cust_shipping_address}`
          )
          .then(function() {
            $this.completeOrder();
          })
          .catch(function(error) {
            $this.$toast.error(`${error.response.data.Message}`);
            $this.cart_ajax = "closed";
          });
      } else {
        $this.cart_ajax = "closed";
        $this.$toast.error(`Please select shipping address`);
      }
    },
    completeOrder() {
      let errorStatus = false;
      if (this.selected_cust_billing_address == "") {
        this.$toast.error(`Please select shipping address`);
        errorStatus = true;
      }

      if (this.selected_payment_method == "") {
        errorStatus = true;
        this.$toast.error(`Please select payment method`);
      }
      // if(this.orderNote == ""){
      //    errorStatus = true;
      //   this.$toast.error(`Please select Order Note`);
      //    this.cart_ajax = "closed";
      // }

      if (errorStatus == false) {
        let custId = localStorage.getItem("custId");
        let payload = {
          custId: custId,
          custBillAdressId:
            this.delivery_type != "Pickup From Store"
              ? this.selected_cust_billing_address
              : "",
          custShipAdressId:
            this.delivery_type != "Pickup From Store"
              ? this.selected_cust_shipping_address
              : "",
          payMethod: this.selected_payment_method,
          transId: "",
          vendorUrlkey: Config.VENDOR_URL_KEY,
          payDetails: "",
          deliveryMode: this.delivery_type,
          slotId: this.selectedDeliverySlot,
          slotDate:
            this.delivery_type != "Pickup From Store"
              ? this.selectedDeliveryDate
              : "",
          orderNote: this.orderNote,
        };
        let $this = this;
        $this.cart_ajax = "open";
        $this
          .placeOrder(payload)
          .then(function(response) {
            $this.$emit("cartUpdated", []);
            $this.$router.push({
              path: `/order-success`,
              query: {
                transaction_id: response.data.Data.orderNumber,
                id: response.data.Data.orderId,
              },
            });
            $this.cart_ajax = "closed";
            $this.$toast.success(`${response.data.Message}`);
          })
          .catch(function(error) {
            $this.cart_ajax = "closed";
            $this.$toast.error(`${error.response.data.Message}`);
          });
      }
    },
    paymentMethod(paymentMethod) {
      this.selected_payment_method = paymentMethod;
    },
    addressForm() {
      this.address_from_open_status = true;
      this.add_address_btn_click = true;
      this.openAddressFormPopup();
    },
    openAddressFormPopup() {
      $("#address_form_popup").addClass("show");
      $("#address_form_popup").css({ display: "block" });
      $(".ps-site-overlay").css({ visibility: "visible", opacity: " .8" });
    },
    updateCart(cartItemInfo) {
      let $this = this;
      let cartUpdateUrl = "";
      if (cartItemInfo.type == "add") {
        cartUpdateUrl = `${Config.BASE_URL}Order/CartItemAddQty?cartItemId=${cartItemInfo.cart_item_id}`;
      } else if (cartItemInfo.type == "sub") {
        cartUpdateUrl = `${Config.BASE_URL}Order/CartItemSubQty?cartItemId=${cartItemInfo.cart_item_id}`;
      }
      if (cartUpdateUrl != "") {
        axios
          .get(`${cartUpdateUrl}`, Config.ADMIN_API_HEADER)
          .then(function(response) {
            if (response.status == 200) {
              let guestId = null;
              let custId = null;
              custId = localStorage.getItem("custId");
              if (custId == null) {
                guestId = localStorage.getItem("guestId");
              }

              let payload = {
                cusId: custId,
                guestId: guestId,
                vendorUrlkey: Config.VENDOR_URL_KEY,
              };
              $this.$store.dispatch("getCart", payload).then(function() {
                $this.cart_info = $this.$store.state.customer_cart;
                $this.$emit("cartUpdated", $this.cart_info);
                $this.storeCartSummary();
              });
            }
          })
          .catch(function(error) {
            $this.$toast.error(`${error.response.data.Message}`);
            $this.qtyReset = 1;
          });
      }
    },
    qtyCntReset() {
      this.qtyReset = 0;
    },
    rmvCartItem(cartItemId) {
      let $this = this;
      $this.cart_ajax = "open";
      $this.$store.dispatch("removeCartItem", cartItemId).then(function() {
        let customerCart = $this.$store.state.customer_cart;
        $this.customer_cart_data = customerCart;
        $this.$emit("cartUpdated", customerCart);
        $this.storeCartSummary();
        $this.cart_ajax = "closed";
        $this.getCustomerAddressList();
      });
    },
    getSelectedCoupon(coupon) {
      this.selectedCoupon = coupon;
      $(".icon-cross2").trigger("click");
      this.applyCustomerOffer();
    },
    applyOffer(offerCode) {
      this.selectedCoupon = offerCode;
      this.applyCustomerOffer();
    },
    applyCustomerOffer() {
      let $this = this;
      $this.$store
        .dispatch("applyCustomerOffer", this.selectedCoupon)
        .then(function(response) {
          $this.$toast.success(`${response.data.Message}`);
        })
        .catch(function(error) {
          $this.$toast.error(`${error.response.data.Message}`);
        });
    },
    getCustomerCouponList() {
      let $this = this;
      $this.$store.dispatch("getCustomerCouponList").then(function(response) {
        $this.coupon_list = response.data.Data;
      });
    },
    fetchCustomerWalletAmount() {
      let $this = this;
      axios
        .get(
          `${Config.BASE_URL}GetWallet?custId=${localStorage.getItem("custId")}`
        )
        .then(function(response) {
          $this.walletTransaction = response.data.Data;
        });
    },
    applyWallet() {
      let $this = this;
      axios
        .post(
          `${Config.BASE_URL}Order/ApplyWallet`,
          {
            custId: localStorage.getItem("custId"),
          },
          Config.CUSTOMER_API_HEADER
        )
        .then(function() {
          $this.storeCartSummary();
        });
    },
    removeWallet() {
      let $this = this;
      axios
        .post(
          `${Config.BASE_URL}Order/RemoveWallet`,
          {
            custId: localStorage.getItem("custId"),
          },
          Config.CUSTOMER_API_HEADER
        )
        .then(function() {
          $this.storeCartSummary();
        });
    },
  },
};
</script>
<style scoped>
.body {
  margin: 0;
  padding: 0 !important;
  direction: unset !important;
  background: #fff;
  font-family: "Roboto";
}
.slotDateDivsub.active {
  color: #7dc113;
  border: 1px solid #7dc113;
  background: #edeff2;
}
.sloatSecaLLROW li {
  cursor: pointer;
}
.sloatSecaLLROW li:hover {
  color: #7dc113;
  border: 1px solid #7dc113;
}
li.active {
  color: #7dc113;
  border: 1px solid #7dc113;
  background: #edeff2;
}
.sloatNavmain li {
  display: inline-block;
}
.delivery_slot_box h2 {
  font-size: 14px;
  color: #afafad;
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.nav-tabs {
  margin-top: 39px;
  border-bottom: 1px #ddd solid;
  margin-bottom: 21px;
  margin-bottom: 10px;

  width: 100%;
}
.nav-tabs .nav-link.active {
  background-color: #71ce63;
  color: #fff;
}
.delivery_slot_box {
  float: left !important;
  clear: both;
  width: 100%;
}
.cart_payment {
  float: left !important;
  clear: both;
  width: 100%;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #71ce63;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.picksotreSec {
  margin-bottom: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 7px;
  padding: 20px 20px;
  height: auto;
  width: 100%;
}
.adreessPageCartSecWreapper {
  width: 70% !important;
  padding-right: 0 !important;
}

#myBtn {
  margin-bottom: 0px;
}

.adreessPageCartSecDiv {
  right: 30px;
}
.subCheckOutWrapperDiv {
  right: 30px;
}
.anotherPaymentMethodDiv {
  margin-bottom: 20px;
}
.payment-btn {
  margin-left: 20px;
  margin-bottom: 30px;
}
.payment-btn button {
  background: rgb(34, 137, 201);
  color: #fff;
  padding: 10px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  width: 180px;
  border-radius: 3px;
  border: none;
}

.myDateInput {
  border: 1px solid #6c757d;
  height: 51px !important;
  padding: 5px !important;
  margin-bottom: 10px !important;
}
@media only screen and (max-width: 1200px) {
  .adreessPageCartSecWreapper {
    width: 100% !important;
  }

  .adreessPageCartSecDiv {
    float: left !important;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .subCheckOutWrapperMain {
    min-height: auto;
    float: left !important;
    width: 100%;
    clear: both;
    z-index: 99999;
  }
  .tab_label {
    width: 143px !important;
    font-size: 11px !important;
  }

  .myDateInput {
    width: 299px !important;
    margin-bottom: 6px !important;
  }
}

.delivery_tab_box input {
  outline: none;
  height: 50px;
  font-size: 14px;
  padding: 0 20px;
  border: none;
  height: 50px;
  background-color: transparent;
  border: 1px solid #6c757d;
  border-radius: 0;
  box-shadow: 0 0 rgb(0 0 0 / 0%);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  box-shadow: 0 0 0 #000;
}
.myDateInput {
  width: 200px;
}
.date_input:nth-child(1) {
  z-index: 99;
}

.slotWrappermain {
  clear: both;
  margin-top: 20px;
  float: left;
  width: 100%;
}

.del_slot {
  z-index: 97;
  position: absolute;
  width: 300px;
  width: 92%;
}
.checkOutMainAllDiv {
  margin-bottom: 10px !important;
}

.check_out_cart {
  max-height: 400px !important;
  margin-bottom: 35px;
}
.subOrdersummaryNav2 {
  margin-bottom: 30px;
}
.applyCodeDivBg {
  margin-left: 0px;
}

.adreessPageCartSecDiv {
  float: right;
  padding-top: 40px;
}

@media only screen and (max-width: 992px) {
  .adreessPageCartSecWreapper {
    float: none;
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .sloatSecaLLROW li {
    width: 43% !important;
  }
  .myDateInput {
    width: 299px !important;
    margin-bottom: 6px !important;
  }
  .slotWrappermain {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 476px) {
  .sloatSecaLLROW li {
    width: 100% !important;
  }
}

/*  */
.modal-vue .overlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-vue .alertModal {
  position: fixed;
  width: 475px;
  z-index: 99999999;
  margin: 0 auto;
  padding: 25px 24px;
  background-color: #f8d7da;
  top: 50%;
  left: 30%;
  border-radius: 4px;
  text-align: center;
}
.msgColor {
  color: #721c24;
  font-size: 20px;
}

.modal-vue .close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 25px;
}
@media only screen and (max-width: 600px) {
  .modal-vue .alertModal {
    position: fixed;
    width: 300px;
    z-index: 99999999;
    margin: 0 auto;
    padding: 25px 24px;
    background-color: #f8d7da;
    top: 50%;
    left: 11%;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1080px) {
  .modal-vue .alertModal {
    position: fixed;
    width: 475px;
    z-index: 99999999;
    margin: 0 auto;
    padding: 25px 24px;
    background-color: #f8d7da;
    top: 50%;
    left: 19%;
  }
}
</style>
